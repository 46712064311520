/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
		var Sage = {
				// All pages
				'common': {
						init: function () {
								// JavaScript to be fired on all pages
								$('.vimeo-modal').click(function (event) {
										event.preventDefault();
										//console.log(termValue);
										$('#video_modal').modal();
										//grab the data id
										var video_id = $(this).data('id');
										// console.log(video_id);
										$('#video_modal .modal-content').css({
												display: 'inline-block'
										}).animate({
												"opacity": 1,
												'min-height': "350px"
										}, 'slow').html('<div class="rectangle-bounce"><img class="loader" src="http://samherbert.net/svg-loaders/svg-loaders/circles.svg" /></div>');
										var htmlData = '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + '<div class="modal-body">';
										htmlData += '<div class="video_wrapper"><iframe id="player' + video_id + '" src="https://player.vimeo.com/video/' + video_id + '?api=1&player_id=player' + video_id + '&amp;autoplay=true" width="630" height="354" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>';
										htmlData += '</div>';
										$('#video_modal .modal-content').html(htmlData);
										$('#video_modal').on('hidden.bs.modal', function () {
												$(".modal-content").empty();
										});
								});
								var isLateralNavAnimating = false;
								//open/close lateral navigation
								$('.hamburger-menu').on('click', function () {
										$('.bar').toggleClass('animate');
										$('body').toggleClass('menu_open');
										$('.menu-overlay').toggleClass('darken');
								});
								$('.menu-overlay').on('click', function () {
										$('.bar').removeClass('animate');
										$('body').removeClass('menu_open');
										$(this).removeClass('darken');
								});
								//dropdown
								//slide down menu
								/*
  $(function() {
    var Accordion = function(el, multiple) {
      this.el = el || {};
      // more then one submenu open?
      this.multiple = multiple || false;
      var dropdownlink = this.el.find('.menu-item-has-children');
      dropdownlink.on('click', {
        el: this.el,
        multiple: this.multiple
      }, this.dropdown);
    };
    Accordion.prototype.dropdown = function(e) {
      var $el = e.data.el,
        $this = $(this),
        //this is the ul.submenuItems
        $next = $this.next();
      $next.slideToggle(50);
      $this.parent().toggleClass('open');
      if (!e.data.multiple) {
        //show only one menu at the same time
        $el.find('.sub-menu').not($next).slideUp().parent().removeClass('open');
      }
    }
    var accordion = new Accordion($('.accordion-menu'), false);
  });
        */
								//popup modal
								//modal view
								function modalFunc() {
										$('.modal-view').click(function (event) {
												event.preventDefault();
												//console.log(termValue);
												$('#view_modal').modal();
												//grab the data id
												var pdf_url = $(this).data('pdf');
												$('#view_modal .modal-content').css({
														display: 'inline-block'
												}).animate({
														"opacity": 1,
														'min-height': "350px"
												}, 'slow').html('<div class="rectangle-bounce"><img class="loader" src="http://samherbert.net/svg-loaders/svg-loaders/circles.svg" /></div>');
												var htmlData = '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
												htmlData += '<div class="modal-body"><div><iframe src="' + pdf_url + '" style="width:100%; height:600px;" frameborder="0"></iframe></div></div>';
												$('#view_modal .modal-content').html(htmlData);
												$('#view_modal').on('hidden.bs.modal', function () {
														$(".modal-content").empty();
												});
										});
										//end click
										$('#view_modal').on('hidden.bs.modal', function () {
												$(".modal-content").empty();
										});
								}
								//e/o modal func
								modalFunc();
						},
						finalize: function () {
								// JavaScript to be fired on all pages, after page specific JS is fired
						}
				},
				// Home page
				'home': {
						init: function () {
								// JavaScript to be fired on the home page
								// onUpdate: function(percent, file) { console.log(percent + '%  -  ' + file); },
						},
						finalize: function () {
								// JavaScript to be fired on the home page, after the init JS
						}
				},
				// About us page, note the change from about-us to about_us.
				'page_template_template_landing': {
						init: function () {
								// JavaScript to be fired on the landing page
								// JavaScript to be fired on the home page
								$('.slider-fade').slick({
										dots: false,
										infinite: true,
										speed: 500,
										fade: true,
										cssEase: 'linear'
								});
								$(window).scroll(function () {
										var hT = $('#scroll-to').offset().top,
												hH = $('#scroll-to').outerHeight(),
												wH = $(window).height(),
												wS = $(this).scrollTop();
										if (wS > (hT + hH - wH)) {
												var cookieName = 'tplCookieConsent'; // The cookie name
												var cookieLifetime = 365; // Cookie expiry in days
												/**
												 * Set a cookie
												 * @param cname - cookie name
												 * @param cvalue - cookie value
												 * @param exdays - expiry in days
												 */
												var _setCookie = function (cname, cvalue, exdays) {
														var d = new Date();
														d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
														var expires = "expires=" + d.toUTCString();
														document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
												};
												/**
												 * Get a cookie
												 * @param cname - cookie name
												 * @returns string
												 */
												var _getCookie = function (cname) {
														var name = cname + "=";
														var ca = document.cookie.split(';');
														for (var i = 0; i < ca.length; i++) {
																var c = ca[i];
																while (c.charAt(0) == ' ') {
																		c = c.substring(1);
																}
																if (c.indexOf(name) == 0) {
																		return c.substring(name.length, c.length);
																}
														}
														return "";
												};
												/**
												 * Should the cookie popup be shown?
												 */
												var _shouldShowPopup = function () {
														if (_getCookie(cookieName)) {
																return false;
														} else {
																return true;
														}
												};
												// Show the cookie popup on load if not previously accepted
												if (_shouldShowPopup()) {
														$('#sign-up-modal').modal('show');
												}
												// Modal dismiss btn - consent
												$('#cookieModalConsent').on('click', function () {
														_setCookie(cookieName, 1, cookieLifetime);
												});
												$(window).off('scroll');
										}
								});
						}
				},
				'single': {
						init: function () {
								//var window_width = jQuery(window).width();
								var stickySidebar = new StickySidebar('.sidebar', {
										topSpacing: 0,
										bottomSpacing: 20,
										resizeSensor: true,
										containerSelector: '.entry-content',
										innerWrapperSelector: '.sidebar__inner'
								});
								/*
        if (window_width < 768) {
          stickySidebar.destroy();
        } else {
          stickySidebar.updateSticky();
        }
        jQuery(window).resize(function() {
          window_width = jQuery(window).width();
          var stickySidebars = new StickySidebar('.sidebar');
          if (window_width < 768) {
            stickySidebars.destroy();
          } else {
            stickySidebars.updateSticky();
          }
        });
				*/
						}
				},
				'page_template_template_collision_pros_mag': {
						init: function () {
								var audios = document.getElementsByTagName('audio');
								if (typeof audios == "undefined" || document.getElementsByTagName("audio").length == 0) {} else {
										audiojs.events.ready(function () {
												var audios = document.getElementsByTagName('audio');
												var a1 = audiojs.create(audios[0], {
														css: false,
														createPlayer: {
																markup: false,
																playPauseClass: 'play-pauseZ',
																scrubberClass: 'scrubberZ',
																progressClass: 'progressZ',
																loaderClass: 'loadedZ',
																timeClass: 'timeZ',
																durationClass: 'durationZ',
																playedClass: 'playedZ',
																errorMessageClass: 'error-messageZ',
																playingClass: 'playingZ',
																loadingClass: 'loadingZ',
																errorClass: 'errorZ'
														}
												});
										});
								}


								$(window).scroll(function () {
										var hT = $('#scroll-to').offset().top,
												hH = $('#scroll-to').outerHeight(),
												wH = $(window).height(),
												wS = $(this).scrollTop();
										if (wS > (hT + hH - wH)) {
												var cookieName = 'tplCookieConsent'; // The cookie name
												var cookieLifetime = 365; // Cookie expiry in days
												/**
												 * Set a cookie
												 * @param cname - cookie name
												 * @param cvalue - cookie value
												 * @param exdays - expiry in days
												 */
												var _setCookie = function (cname, cvalue, exdays) {
														var d = new Date();
														d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
														var expires = "expires=" + d.toUTCString();
														document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
												};
												/**
												 * Get a cookie
												 * @param cname - cookie name
												 * @returns string
												 */
												var _getCookie = function (cname) {
														var name = cname + "=";
														var ca = document.cookie.split(';');
														for (var i = 0; i < ca.length; i++) {
																var c = ca[i];
																while (c.charAt(0) == ' ') {
																		c = c.substring(1);
																}
																if (c.indexOf(name) == 0) {
																		return c.substring(name.length, c.length);
																}
														}
														return "";
												};
												/**
												 * Should the cookie popup be shown?
												 */
												var _shouldShowPopup = function () {
														if (_getCookie(cookieName)) {
																return false;
														} else {
																return true;
														}
												};
												// Show the cookie popup on load if not previously accepted
												if (_shouldShowPopup()) {
														$('#sign-up-modal').modal('show');
												}
												// Modal dismiss btn - consent
												$('#cookieModalConsent').on('click', function () {
														_setCookie(cookieName, 1, cookieLifetime);
												});
												$(window).off('scroll');
										}
								});


						}
				},
				'single_collision_pros_pub': {
						init: function () {
								//$("#sidebar").stick_in_parent();
								var audios = document.getElementsByTagName('audio');
								if (typeof audios == "undefined" || document.getElementsByTagName("audio").length == 0) {} else {
										audiojs.events.ready(function () {
												var audios = document.getElementsByTagName('audio');
												var a1 = audiojs.create(audios[0], {
														css: false,
														createPlayer: {
																markup: false,
																playPauseClass: 'play-pauseZ',
																scrubberClass: 'scrubberZ',
																progressClass: 'progressZ',
																loaderClass: 'loadedZ',
																timeClass: 'timeZ',
																durationClass: 'durationZ',
																playedClass: 'playedZ',
																errorMessageClass: 'error-messageZ',
																playingClass: 'playingZ',
																loadingClass: 'loadingZ',
																errorClass: 'errorZ'
														}
												});
										});
								}
						}
				},
				'page_template_template_media_library': {
						init: function () {
								var a = audiojs;
								a.events.ready(function () {
										var a1 = a.createAll();
								});
						}
				},
				'search_results': {
						init: function () {
								var a = audiojs;
								a.events.ready(function () {
										var a1 = a.createAll();
								});
						}
				},

				'page_template_template_pdf': {
						init: function () {


								function pdfFunc() {
										$('.part-type a').click(function (event) {
												event.preventDefault();
												var pdfTemplate = $(this).data('part'),
														partImg = $(this).data("img"),
														descCopy = $(this).data("copy");

												$('[data-desc]').html(descCopy)

												$('.part-type a').removeClass("active");
												$(this).addClass("active");
												$('.pdf-bg img').attr('src', partImg);

												$('.btn-next').addClass("active").fadeIn(100).animate({
														'top': '-10px',
														'opacity': 1
												}, 500);

												nextFunc();

										});

								}
								pdfFunc();

								function nextFunc() {
										$('.btn-next').click(function (event) {
												event.preventDefault();
												$(this).hide();

												$(".row-form").addClass("_activate");
												$(".part-row").hide();

												$("html, body").stop(true, true).animate({
														scrollTop: $(".row-form").offset().top
												}, 800);


										});
								}

								function backFunc() {
										$('.btn-back').click(function (event) {
												event.preventDefault();
												//$(this).hide();
												$('.btn-next').show();
												$(".row-form").removeClass("_activate");
												$(".part-row").show();
												$('html, body').animate({
														scrollTop: $(".part-row").offset().top
												}, 800);


										});

								}

								backFunc();


								// Function for Preview Image.
								jQuery(function () {
										jQuery(":file").change(function () {

												if (this.files && this.files[0]) {
														var reader = new FileReader();
														reader.onload = imageIsLoaded;
														reader.readAsDataURL(this.files[0]);
												}


										});
								});

								function imageIsLoaded(e) {
										jQuery('#message').css("display", "none");
										jQuery('#preview').css("display", "block");
										jQuery('#previewimg').attr('src', e.target.result);
										$('.placeholder-logo').remove();

								};
								// Function for Deleting Preview Image.
								jQuery("#deleteimg").click(function () {
										jQuery('#preview').css("display", "none");
										jQuery('#file').val("");
								});
								// Function for Displaying Details of Uploaded Image.
								jQuery("#submit").click(function () {
										jQuery('#preview').css("display", "none");
										jQuery('#message').css("display", "block");
								});


								$('.pdf-form-fields input[type=text]').keyup(function (event) {
										//var dInput = this.value;
										//console.log(dInput);
										var title = $(this).data('name');
										console.log(title);
										var dInput = this.value;
										//console.log(dInput);
										$("[" + title + "]").html(dInput);
								});


								$('.pdf-form-fields textarea').keyup(function (event) {
										var desc = $(this).data('desc');
										var dtextarea = this.value;
										$("[" + desc + "]").html(dtextarea);
								});


								$("select.state-select").change(function () {
										var selectedState = $(this).children("option:selected").val();
									console.log(selectedState);
										$("[data-state]").html(selectedState);
								});


								$("form.pdf-form-fields").on('submit', function (e) {
										e.preventDefault();
										var form = $(this);
										form.parsley().validate();
										if (form.parsley().isValid()) {
												var element = document.getElementById('element-to-print');

												// Generate the PDF.
												html2pdf().from(element).set({
														margin: [0.5, 1, 1, 1],
														filename: 'my_pdf_file.pdf',
														html2canvas: {
																scale: 2
														},
														image: {
																type: 'jpeg',
																quality: 1
														},
														jsPDF: {
																orientation: 'portrait',
																unit: 'in',
																format: 'letter',
																compressPDF: false
														}
												}).save();
										}
								});
							
							
								//reset form fields on back click
							
							$(".btn-back").on("click", function(){
							 //code here
							 	$('.pdf-form-fields')[0].reset();
							});
							
							

								//end


						}
				},

		};
		// The routing fires all common scripts, followed by the page specific scripts.
		// Add additional events for more control over timing e.g. a finalize event
		var UTIL = {
				fire: function (func, funcname, args) {
						var fire;
						var namespace = Sage;
						funcname = (funcname === undefined) ? 'init' : funcname;
						fire = func !== '';
						fire = fire && namespace[func];
						fire = fire && typeof namespace[func][funcname] === 'function';
						if (fire) {
								namespace[func][funcname](args);
						}
				},
				loadEvents: function () {
						// Fire common init JS
						UTIL.fire('common');
						// Fire page-specific init JS, and then finalize JS
						$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
								UTIL.fire(classnm);
								UTIL.fire(classnm, 'finalize');
						});
						// Fire common finalize JS
						UTIL.fire('common', 'finalize');
				}
		};
		// Load Events
		$(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
